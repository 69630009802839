import { useEffect } from "react";

const AppView = () => {
  useEffect(() => {
    document.title = "App - Fluff Money";
  });

  return (
    <div id="wrapper" className="clearfix">
      <section id="content">
        <div className="content-wrap">
          <div className="container clearfix">
            <div className="row clearfix align-items-center">
              <div className="col-lg-6 center">
                <div className="d-none d-lg-block"></div>
                <div className="emphasis-title bottommargin-sm">
                  <h2>Fluff Money App</h2>
                </div>
                <p className="lead">Earn Free Crypto Every Day</p>
                <p>Coming for Android and iOS soon</p>
              </div>

              <div className="col-lg-6 center">
                <img
                  className="rounded-corners"
                  src={require("../supporting_files/images//app/screenshots_main.jpg")}
                  alt="App Screenshots"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AppView;
