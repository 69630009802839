import { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./custom.css";
import "./style.css";
import "./HomeView.css";
import mainBg from "../supporting_files/images/main_bg.jpg";

const HomeView = () => {
  useEffect(() => {
    document.title = "Fluff Money";
  });

  return (
    <>
      <section
        id="slider"
        className="slider-element slider-parallax min-vh-60 min-vh-md-100 include-header"
      >
        <div
          className="slider-inner-element"
          style={{
            background: "url(" + mainBg + ") center center no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="vertical-middle slider-element-fade">
            <div className="container dark center">
              <div style={{ margin: "32px 0" }}>
                <div className="emphasis-title">
                  <p>
                    <img
                      id="main-logo"
                      src={
                        require("../supporting_files/images/logo.svg").default
                      }
                      alt="Logo"
                      height="100"
                    />
                  </p>
                  <h1>Fluff Money</h1>
                  <h4 id="slogan" className="font-body">
                    mluff foney
                  </h4>
                </div>
                <div id="main-buttons" style={{ margin: "auto" }}>
                  <a
                    href="/fluf/buy"
                    className="button button-large button-light"
                    target="_blank"
                  >
                    Buy/Sell on SOL
                  </a>

                  <a
                    href="https://pancakeswap.finance/?outputCurrency=0x5285E2598FD8eBEAbF808AD5561Be7F03E1c78af&inputCurrency=BNB"
                    className="button button-large button-light"
                    target="_blank"
                  >
                    Buy/Sell on BSC
                  </a>
                  <a
                    href="/fluf/stake"
                    className="button button-large button-light"
                    target="_blank"
                  >
                    Stake FLUF (BSC only)
                  </a>
                  <a
                    href="/fluf/charts"
                    className="button button-large button-light"
                    target="_blank"
                  >
                    Charts (SOL)
                  </a>
                  <a
                    href="https://dexscreener.com/bsc/0x07fda3b30d2545bdeb7ddf90ef5c3ab03bafefff"
                    className="button button-large button-light"
                    target="_blank"
                  >
                    Charts (BSC)
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="content">
        <div className="content-wrap">
          <Container>
            <Row className="align-items-center">
              <Col md className="center">
                <div className="emphasis-title bottommargin-sm">
                  <h2>Fluff Money App</h2>
                </div>
                <p className="lead">
                  Play to Earn. Learn to Play. this slogan sucks ass
                  <br />
                  Coming in 2024 (holy fuck i am procastinating like my life
                  depends on it)
                </p>
              </Col>
              <Col md className="center">
                <img
                  src={require("../supporting_files/images/app/screenshots_main.jpg")}
                  style={{ maxWidth: "300px" }}
                  alt="Fluff Money App"
                  data-animate="fadeInRight"
                />
              </Col>
            </Row>

            <div className="line"></div>

            <Row className="align-items-center">
              <Col md className="center">
                <h2>Staking</h2>
                <p className="lead">
                  Stake your FLUF to earn passive income.
                  <br />
                  <a href="/fluf/stake">
                    Check out the Staking Dapp! (for BSC only, SOL update coming
                    soon. maybe in 2089 idk)
                  </a>
                </p>
              </Col>
              <Col md className="center">
                <img
                  className="rounded-corners"
                  src={require("../supporting_files/images/staking.jpg")}
                  alt="Staking"
                  data-animate="fadeInRight"
                />
              </Col>
            </Row>

            <div className="line"></div>
            <div className="clear"></div>

            <Row className="align-items-center">
              <Col md className="center">
                <h2>FluffDEX</h2>
                <p className="lead">
                  Amazingly low trading fees.
                  <br />
                  Coming soon. an even more amazing thing is the sweet release
                  of death i guess :XXXXXXXXXX
                </p>
              </Col>
              <Col md className="center">
                <img
                  className="rounded-corners"
                  src={require("../supporting_files/images/fluffdex.jpg")}
                  alt="FluffDEX"
                />
              </Col>
            </Row>

            <div className="line"></div>
            <div className="clear"></div>
          </Container>

          <div id="roadmap" className="container clearfix">
            <div className="row clearfix">
              <div className="emphasis-title bottommargin-sm">
                <h2 className="center">Roadmap</h2>
              </div>
              <div className="col-lg-3 center">
                <div className="emphasis-title bottommargin-sm">
                  <h3>Phase 1 - 2022</h3>
                </div>
                <ul>
                  <li>
                    Mobile App - Beta (came out in Jan 2023 and i'll need to
                    redo the whole entire thing basically. life is wonderful and
                    flowers and blooming orchids and trallala and jumping on
                    fucking wheat fields with your friends. i wanna be a normie
                    so bad) ✓
                  </li>
                  <li>Staking Dapp ✓</li>
                  <li>Social Marketing Campaigns ✓</li>
                  <li>NFT Drops ✓</li>
                </ul>
              </div>
              <div className="col-lg-3 center">
                <div className="emphasis-title bottommargin-sm">
                  <h3>Phase 2 - 2023</h3>
                </div>
                <ul>
                  <li>ETH version - (got created, the presale failed) ✓</li>
                  <li>Social Marketing Campaigns ✓</li>
                  <li>NFT Drops ✓</li>
                </ul>
              </div>
              <div className="col-lg-3 center">
                <div className="emphasis-title bottommargin-sm">
                  <h3>Phase 3 - 2024</h3>
                </div>
                <ul>
                  <li>
                    Services purchasable with FLUF tokens (postponed from 2023)
                  </li>
                  <li>FluffDEX (postponed from 2022)</li>
                  <li>Mobile App - Release (postponed from 2022)</li>
                  <li>Social Marketing Campaigns</li>
                  <li>NFT Drops</li>
                </ul>
              </div>
              <div className="col-lg-3 center">
                <div className="emphasis-title bottommargin-sm">
                  <h3>Phase 4 - 2025</h3>
                </div>
                <ul>
                  <li>
                    NFT Marketplace (postponed from 2023. jesus fucking christ i
                    really like to torture future self arent i)
                  </li>
                  <li>Launchpad (postponed from 2023)</li>
                  <li>Social Marketing Campaigns</li>
                  <li>NFT Drops</li>
                </ul>
              </div>
            </div>

            <div className="line"></div>
            <div className="clear"></div>
          </div>

          <div id="faq" className="page-section container">
            <h2 className="center font-body bottommargin-lg">
              Frequently Asked Questions
            </h2>

            <div className="row topmargin-sm clearfix">
              <div className="col-lg-5 offset-lg-1 col-md-6 bottommargin-sm">
                <h4>How can I trade FLUF?</h4>
                <p>
                  <a href="/fluf/buy">Click here</a>, connect your wallet, set
                  slippage as low as you want (0.1% or 0.5% works), approve and
                  send your transaction. Simple as that!
                </p>
              </div>
              <div className="col-lg-5 col-md-6 bottommargin-sm">
                <h4>Does FLUF have reflections?</h4>
                <p>
                  Yes,{" "}
                  <a href="/fluf/stake">
                    if you stake FLUF (only for the BSC version as of now)
                  </a>
                  , it reflects a certain amount. Besides staking, there are
                  frequent giveaways that make being a FLUF holder beneficial.
                  Also, there will be services only available to FLUF holders in
                  the future.
                </p>
              </div>
              <div className="col-lg-5 offset-lg-1 col-md-6 bottommargin-sm">
                <h4>Does FLUF have taxes?</h4>
                <p>
                  FLUF itself doesn't have buy or sell taxes. Staking FLUF, on
                  the other hand, has a fee.{" "}
                  <a href="/fluf/stake">Staking and more info here.</a>
                </p>
              </div>
              <div className="col-lg-5 col-md-6 bottommargin-sm">
                <h4>Why is there a wallet holding 28.2% in the SOL version?</h4>
                <p>
                  <a
                    href="https://solscan.io/account/1nc1nerator11111111111111111111111111111111"
                    target="_blank"
                  >
                    That's the incinerator wallet.
                  </a>{" "}
                  The amount was sent to the wallet instead of being burnt the
                  "correct" way. Since the wallet has no owner, it stayed and
                  will stay that way. Oh well.
                </p>
              </div>
            </div>

            <div className="col-lg-5 col-md-6 bottommargin-sm">
              <h4>
                DEV WEN MARKETING WEN DEX WEN JUPITER WEN MOON WHEN GREEN CANDLE
                WHY RED CANDLE I WILL CONTACT SOLANA SUPPORT
              </h4>
              <p>
                i cannot wait for ai to take over my life and i will be able to
                just sit back and relax and watch the world burn. claude knows
                me so well holy shit this was another autocompleted sentence and
                it hurts
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeView;
